var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "mt": "50px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": ['left', 'center'],
      "flex-dir": ['column', 'row']
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "xl",
      "font-weight": "700"
    }
  }, [_vm._v(" Indikator Pertanyaan Terpilih ")]), _c('c-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": {
        name: 'nutri.progress-tracker.edit'
      }
    }
  }, [_vm._v(" Edit Indikator ")])], 1), _c('c-box', {
    attrs: {
      "mt": "30px",
      "border-width": "1px",
      "border-color": "primary.400",
      "rounded": "lg",
      "px": "45px",
      "py": "50px"
    }
  }, [_vm._l(_vm.steps, function (step, index) {
    return [_vm.stepIndex === index ? _c('CardStep', {
      key: index,
      attrs: {
        "current": index + 1,
        "total": _vm.steps.length,
        "is-prev": index !== 0,
        "is-next": index < _vm.steps.length - 1
      },
      on: {
        "prev": function prev($event) {
          _vm.stepIndex--;
        },
        "next": function next($event) {
          _vm.stepIndex++;
        }
      },
      scopedSlots: _vm._u([{
        key: "next",
        fn: function fn() {
          return [index === _vm.steps.length - 1 ? _c('c-text', [_vm._v(" Submit ")]) : _c('c-text', [_vm._v(" Selanjutnya ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('c-text', {
      attrs: {
        "font-weight": "700"
      }
    }, [_vm._v(" Bagian " + _vm._s(step.title) + " ")]), _c('ul', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "mt": "20px",
        "pl": "20px"
      }
    }, _vm._l(step.questions, function (question, index_) {
      return _c('li', {
        key: index_
      }, [_vm._v(" " + _vm._s(question.question) + " ")]);
    }), 0)], 1) : _vm._e()];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }